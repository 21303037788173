<template>
  <div>
    <v-card class="rscope" dark>
      <v-card-title>
        <span class="settings-header mb-4" style="font-size: 15px"
          >Személyes Adatok</span
        >
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('store.deliveryFirstname')"
          v-model="model.firstName"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          :label="$t('store.deliveryLastname')"
          v-model="model.lastName"
          required
          :rules="defaultRules"
        ></v-text-field>
        <v-text-field
          :label="$t('agent.phone')"
          v-model="model.telephoneNumber"
          required
          type="number"
          :rules="defaultRules"
        ></v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="rscope" dark>
      <v-card-title>
        <span class="settings-header mb-4" style="font-size: 15px"
          >Céges Adatok</span
        >
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Cégnév"
          v-model="model.store.firmName"
          required
          :rules="defaultRules"
        ></v-text-field>
        <v-text-field
          label="Székhely"
          v-model="model.store.firmLocation"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          label="Adószam"
          v-model="model.store.firmTaxNumber"
          required
          :rules="defaultRules"
        ></v-text-field>

        <v-text-field
          label="Bolt Címe"
          v-model="model.store.storeAddress"
          required
          :rules="defaultRules"
        ></v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="rscope" dark>
      <v-card-title>
        <span class="settings-header mb-4" style="font-size: 15px"
          >Szállítási Cím</span
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            :label="$t('store.deliveryAddress')"
            v-model="model.store.deliveryAddress"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->
          <v-text-field
            :label="$t('store.deliveryCity')"
            v-model="model.store.deliveryCity"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->
          <v-text-field
            :label="$t('store.zipCode')"
            v-model="model.store.deliveryZipCode"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->

          <v-text-field
            :label="$t('store.deliveryDistrict')"
            v-model="model.store.deliveryDistrict"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->
          <v-text-field
            :label="$t('store.deliveryFirstname')"
            v-model="model.store.deliveryFirstname"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->
          <v-text-field
            :label="$t('store.deliveryLastname')"
            v-model="model.store.deliveryLastname"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->
          <v-text-field
            :label="$t('store.deliveryPhoneNumber')"
            v-model="model.store.deliveryPhoneNumber"
            required
            :rules="defaultRules"
          ></v-text-field>
          <!--  -->
        </v-form>
        <v-btn class="fp" outlined rounded @click="submit()">Mentés</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import { fv } from "../util/functions.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE } from "../util/constants";
export default {
  props: ["valid", "isAddress"],
  data() {
    return {
      defaultRules: fv.default,
      emailRules: fv.emailRules,
      isValid: false,
      model: {
        store: {},
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `client/users/options`,
          payload: null,
        })
        .then((res) => {
          // console.log(res);
          this.model = res;
        });
    },
    async submit() {
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "PUT",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(this.model),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}client/users/options`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: "Elmentve",
          });
          this.getList();
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
};
</script>
