<template>
  <div class="Settings">
    <v-card dark
      ><v-card-text
        ><v-btn @click="fridgeSetup()" elevation="6" block dark
          ><img class="pr-2" src="../assets/svg/fridge.svg" />{{
            $t("navigation.huto-beallitasok")
          }}
          <v-icon class="ml-auto">mdi-arrow-right</v-icon></v-btn
        ></v-card-text
      ></v-card
    >
    <v-container fluid>
      <password-reset></password-reset>
      <v-spacer></v-spacer>
      <user-profile></user-profile>
    </v-container>
  </div>
</template>

<script>
// import Pager from "../components/Pager.vue";
import eventBus from "../eventBus.js";
import PasswordReset from "../components/PasswordReset.vue";
import UserProfile from "../components/UserProfile.vue";
export default {
  name: "settingsView",
  components: { PasswordReset, UserProfile },
  created() {},
  data() {
    return {};
  },
  methods: {
    fridgeSetup() {
      this.$router.push("/huto-beallitasok");
    },
  },
  computed: {},
};
</script>

<style scoped></style>
<style>
.v-card__title .settings-header {
  display: block;
  text-align: center;
  color: var(--red);
}
</style>
