<template>
  <v-card class="rscope" dark>
    <v-card-title>
      <span class="settings-header mb-4" style="font-size: 15px"
        >Jelszó Csere</span
      >
    </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-text-field
          label="Email"
          v-model="model.email"
          required
          disabled
          :rules="defaultRules"
        ></v-text-field>
        <!--  -->
        <v-text-field
          v-model="model.password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          label="Jelszó"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
        <!--  -->
        <v-text-field
          v-model="confirmPassword"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          label="Megerősítés"
          counter
          @click:append="show2 = !show2"
        ></v-text-field>
        <!--  -->
      </v-form>
      <small v-if="model.password !== confirmPassword"
        >A jelszavak nem egyformák</small
      >
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!(isValid && model.password === confirmPassword)"
        class="fp"
        outlined
        rounded
        @click="submit()"
        >Mentés</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import { fv } from "../util/functions.js";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE, DEFAULT_HEADER } from "../util/constants";
export default {
  props: ["valid", "isAddress"],
  data() {
    return {
      show1: false,
      show2: false,
      defaultRules: fv.default,
      emailRules: fv.emailRules,
      rules: {
        required: (v) => !!v || "Kötelező.",
        min: (v) => v.length >= 8 || "Min 8 karakter",
      },
      pwRules: fv.pwRules,
      isValid: false,
      confirmPassword: "",
      model: {
        email: this.$store.getters.getUser.email,
        password: "",
      },
    };
  },
  mounted() {},
  methods: {
    async submit() {
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "PUT",
        headers: DEFAULT_HEADER,
        body: JSON.stringify(this.model),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}client/users/reset-password`,
          requestOptions
        );

        if (response.ok) {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: "Elmentve",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
};
</script>
